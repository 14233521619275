import { ChainID, ChainName } from "@omnity/widget/src/types";
import { CHAIN_ID_NAME_MAP } from "@omnity/widget/src/utils/constants";
import { EXT_CHAINS } from "./constants";
import { getChainIdFromName } from "@omnity/widget/src/utils/chains";
import { TicketItem } from "src/types";

export function getChainName(chain_id: ChainID) {
  const chain_name = CHAIN_ID_NAME_MAP[chain_id]?.chainName as ChainName;
  if (chain_name) {
    return chain_name;
  }
  const extra_chain_name = EXT_CHAINS[chain_id as string];

  return extra_chain_name ?? chain_id;
}

export function extGetChainIdFromName(chain_name: string) {
  const ext_chain_ids = Object.keys(EXT_CHAINS);
  const ext_chain_names = Object.values(EXT_CHAINS);
  const idx = ext_chain_names.indexOf(chain_name);
  if (idx !== -1) {
    return [ext_chain_ids[idx]];
  }
  return getChainIdFromName(chain_name);
}

export function getAssetTypes(chain_id: ChainID) {
  if ([ChainID.eICP, ChainID.Bitfinity].includes(chain_id)) {
    return ["Runes", "Icrc"];
  }

  if (
    (chain_id as string) === "osmosis-1" ||
    (chain_id as string) === "osmo-test-5"
  ) {
    return ["Icrc"];
  }
  return ["Runes"];
}

export function getAssetProtocol(ticket?: Pick<TicketItem, "token">) {
  if (!ticket) {
    return "";
  }
  const [chain, protocol] = ticket.token.split("-");
  if (chain === ChainID.sICP) {
    return "icrc";
  } else if (protocol === "runes") {
    return "runes";
  } else if (protocol === "brc20") {
    return "brc-20";
  }
  return "";
}

export function uniqChains(chain_ids: ChainID[]) {
  if (chain_ids.includes(ChainID.eICP) && chain_ids.includes(ChainID.sICP)) {
    return [
      ChainID.sICP,
      ...chain_ids.filter((id) => id !== ChainID.sICP && id !== ChainID.eICP),
    ];
  }
  return chain_ids;
}

const bitcoinIndexerApi = "https://mempool.space/api";
export async function checkConfirmations(txHash: string): Promise<number> {
  try {
    if (!txHash) {
      throw new Error("Transaction hash is required");
    }
    const tx = await fetch(`${bitcoinIndexerApi}/tx/${txHash}`).then((res) =>
      res.json(),
    );
    let txBlockHeight = tx.status.block_height;
    if (!Number.isInteger(txBlockHeight)) {
      throw new Error("Transaction not found");
    }
    const currentBlockHeight = await fetch(
      `${bitcoinIndexerApi}/blocks/tip/height`,
    ).then((res) => res.json());
    return currentBlockHeight - txBlockHeight + 1;
  } catch (error) {
    return 0;
  }
}
