import { isAddress, getAddress } from "viem";
import { TokenMeta } from "src/types";
import BitcoinCustomsService from "@omnity/widget/src/services/BitcoinCustomsService";
import ICPService from "@omnity/widget/src/services/ICPService";
import EvmRouteService from "@omnity/widget/src/services/EvmRouteService";
import SolanaRouteService from "@omnity/widget/src/services/SolanaRouteService";

const RUNES_PREFIX = "Bitcoin-runes-";
const ICP_PREFIX = "sICP-native-";
const ICRC_PREFIX = "sICP-icrc-";
const PREFIXS = [RUNES_PREFIX, ICP_PREFIX, ICRC_PREFIX];

export function completeTokenId(id: string, runes: TokenMeta[]) {
  const result = PREFIXS.find((prefix) => {
    return runes.find((rune) => rune.token_id === prefix + id);
  });
  return result ? result + id : id;
}

export function beautifyTokenId(id: string) {
  const result = id.split("-");
  return result[result.length - 1];
}

export function beautifyAddress(addr?: string) {
  const _addr = (addr ?? "").trim().replace(/\"/g, "");
  if (!_addr) {
    return "";
  }
  if (isAddress(_addr)) {
    return getAddress(_addr);
  }
  return _addr;
}

export function getKeywordType(keyword: string) {
  if (getChainByAddress(keyword) !== "unknown") {
    return "address";
  }

  return "tx";
}

export function getChainByAddress(addr: string) {
  if (EvmRouteService.validateAddress(addr)) {
    return "EVM";
  } else if (ICPService.validateAddress(addr)) {
    return "ICP";
  } else if (BitcoinCustomsService.validateAddress(addr)) {
    return "BTC";
  } else if (SolanaRouteService.validateAddress(addr)) {
    return "Solana";
  }
  return "unknown";
}
