import { getICPSwapIndexService } from "src/candids";
import { TokenMeta } from "src/types";

async function fetchRunePrice(rune_id?: string) {
  try {
    if (!rune_id) {
      return "0";
    }
    const res = await fetch(
      `https://api.runego.com/api/v1/rune/${rune_id}`,
    ).then((res) => res.json());
    return res.data.runeInfo.floorPriceUsd;
  } catch (error) {
    return "0";
  }
}

export async function fetchTokenPrice(tokens: TokenMeta[]) {
  try {
    const runesTokens = tokens.filter((token) =>
      token.token_id.startsWith("Bitcoin-runes-"),
    );
    const fetchedRunesTokens = await Promise.all(
      runesTokens.map((rune) => fetchRunePrice(rune.metadata.rune_id)),
    ).then((results) => {
      return runesTokens.map((rune, index) => ({
        ...rune,
        price: results[index],
      }));
    });

    const icpTokens = tokens.filter((token) =>
      token.token_id.startsWith("sICP-"),
    );
    const nodeIndex = await getICPSwapIndexService();
    const allICPTokens = await nodeIndex.getAllTokens();

    const fetchedIcpTokens = icpTokens.map((icpToken) => {
      const t = allICPTokens.find(
        (t: any) => t.address === icpToken.metadata.ledger_id,
      );
      if (t) {
        return { ...icpToken, price: `${t.priceUSD}` };
      }
      return icpToken;
    });

    const brc20Tokens = tokens.filter((token) =>
      token.token_id.startsWith("Bitcoinbrc20-brc20"),
    );

    return [...fetchedRunesTokens, ...fetchedIcpTokens, ...brc20Tokens];
  } catch (error) {
    return tokens;
  }
}
