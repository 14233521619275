import {
  BridgeStep,
  Chain,
  ChainID,
  OnBridgeParams,
  Ticket,
  TicketStatus,
  Token,
  TxStatus,
  OnBurnParams,
  TicketStatusResult,
  TicketAction,
  BridgeFee,
} from "../types";
import BaseService from "./BaseService";
import { ActorSubclass } from "@dfinity/agent";
import {
  idlFactory as BitcoinCustomInterfaceFactory,
  TokenResp,
  _SERVICE,
} from "./candids/BitcoinBrc20Customs.did";
import { createActor } from "./candids/index";
import { validate, Network } from "bitcoin-address-validation";
import { formatGenerateTicketError } from "../utils/helper";
import { isEvmChain } from "../utils/chains";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";

export const runesIndexerApi =
  "https://hasura-secondary-graphql-engine-2252klcbva-uc.a.run.app";

const bitcoinIndexerApi = "https://mempool.space/api";

export default class BitcoinBrc20CustomsService extends BaseService {
  actor: ActorSubclass<_SERVICE>;
  static BTC_CONFIRMATIONS_LIMIT = 4;

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(
      chain.canister_id,
      BitcoinCustomInterfaceFactory,
    );
  }

  async getTokenList(): Promise<Token[]> {
    const tokenList = await this.actor.get_token_list();
    return tokenList.map(transformHubToken);
  }

  async fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    try {
      let tokenList = this.chain.token_list || [];
      if (Array.isArray(token_ids) && token_ids.length > 0) {
        tokenList = token_ids
          .map((id) => tokenList.find((r) => r.token_id === id))
          .filter((t) => !!t) as any;
      }

      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          const { balance, composed_balance } = await getRuneTokenBalance(
            t.id,
            address,
          );
          return { ...t, balance, composed_balance };
        }),
      );
      return tokens;
    } catch (error) {
      return [];
    }
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Prepare",
        description: "Generate deposit address",
      },
      {
        title: "Transfer",
        description: "Send your assets to the deposit address",
      },
    ];
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    const {
      targetAddr,
      targetChainId,
      setStep,
      transfer,
      sourceAddr,
      token,
      feeRate,
      amount,
    } = params;
    const depositAddr = await this.actor.get_btc_address({
      target_chain_id: targetChainId,
      receiver: targetAddr,
    });
    setStep && setStep(1);

    if (!transfer) {
      throw new Error("Transfer function is required");
    }
    const tx_hash = await transfer({
      wallet_address: sourceAddr,
      receiver_address: depositAddr,
      rune_name: token.name,
      fee_rate: feeRate,
      amount,
    });

    setStep && setStep(2);

    return tx_hash;
  }

  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async generateTicket(
    ticket: Ticket,
  ): Promise<{ finalized: boolean; message?: string }> {
    if (!ticket.ticket_id) {
      throw new Error("Ticket not found");
    }
    if (BigInt(ticket.amount) === 0n) {
      throw new Error(
        `Ticket finalizing error: Invalid amount ${ticket.amount}`,
      );
    }
    const tokens = await this.fetchTokens([ticket.token]);
    const token = tokens[0];
    if (!token) {
      throw new Error("Token not found");
    }
    let result;
    if (ticket.type === TicketAction.Mint) {
      result = await this.actor.generate_ticket({
        txid: ticket.mint_tx_hash!,
        target_chain_id: ticket.src_chain,
        amount: BigInt(ticket.amount),
        receiver: isEvmChain(ticket.src_chain)
          ? ticket.receiver.toLowerCase()
          : ticket.receiver,
        rune_id: token.id,
      });
    } else {
      result = await this.actor.generate_ticket({
        txid: ticket.ticket_id,
        target_chain_id: ticket.dst_chain,
        amount: BigInt(ticket.amount),
        receiver: ticket.receiver,
        rune_id: token.id,
      });
    }

    if ("Ok" in result) {
      posthog.capture("ticket generate ok", {
        ...ticket,
        token_id: ticket.token,
      });
      return { finalized: true };
    }

    const error = formatGenerateTicketError(result.Err);
    posthog.capture("ticket generate error", {
      ...ticket,
      token_id: ticket.token,
      error,
    });
    Sentry.captureException({
      message: "Failed to generate ticket",
      error,
      chainId: this.chain.chain_id,
      hash: ticket.ticket_id,
    });
    return { finalized: false, message: error };
  }

  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    const res = await this.actor.release_token_status(ticket_id);
    let status = Object.keys(res)[0] as TicketStatus;
    const statusValue = Object.values(res)[0];
    let tx_hash = "";
    if (
      [
        TicketStatus.Confirmed,
        TicketStatus.Submitted,
        TicketStatus.Sending,
      ].includes(status)
    ) {
      status =
        TicketStatus.Confirmed === status ? TicketStatus.Finalized : status;
      tx_hash = statusValue ?? "";
    }
    return {
      status,
      tx_hash,
    };
  }

  async getOutputTicketStatus(ticket_id: string): Promise<TicketStatus> {
    // const res = await this.actor.generate_ticket_status(ticket_id);
    // const status = Object.keys(res)[0];
    // return status as TicketStatus;
    throw new Error("Method not implemented.");
  }

  static async fetchFeeRate() {
    try {
      const gasFee = await fetch(
        `${bitcoinIndexerApi}/v1/fees/recommended`,
      ).then((res) => res.json());
      return {
        fastestFee: gasFee.fastestFee,
        halfHourFee: gasFee.halfHourFee,
        hourFee: gasFee.hourFee,
      };
    } catch (error) {
      return {
        fastestFee: 10,
        halfHourFee: 10,
        hourFee: 10,
      };
    }
  }

  static async getTxStatus(ticket: Ticket): Promise<TxStatus> {
    try {
      if (!ticket.ticket_id) {
        throw new Error("Transaction hash is required");
      }
      const tx = await fetch(
        `${bitcoinIndexerApi}/tx/${ticket.ticket_id}`,
      ).then((res) => res.json());
      return !!tx ? "success" : "pending";
    } catch (error) {
      return "pending";
    }
  }

  static validateAddress(addr: string): boolean {
    return validate(addr, Network.mainnet);
  }

  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    return Promise.resolve({
      fee: BigInt(0),
      symbol: "BTC",
      decimals: 8,
    });
  }
}

export function transformHubToken(hubToken: TokenResp) {
  const { decimals, icon, symbol, token_id } = hubToken;
  const token: Token = {
    id: symbol.toLowerCase(),
    decimals,
    icon: icon[0],
    symbol,
    token_id,
    balance: 0n,
    name: symbol,
    fee: 0n,
    chain_id: ChainID.BitcoinBrc20,
  };
  return token;
}

export async function getRuneTokenBalance(ticker: string, address?: string) {
  let balance = 0n;
  let available = 0n;
  try {
    if (!ticker) {
      throw new Error("Ticker is required");
    }
    if (!address) {
      throw new Error("Address is required");
    }
    const balanceResult = await fetch(
      `https://open-api.unisat.io/v1/indexer/address/${address}/brc20/${ticker}/info`,
      {
        headers: {
          Authorization:
            "Bearer 82d20a04f30da508a8381edb8ccf5b374ed937049da56df573a312e44aceae27",
          accept: "application/json",
        },
      },
    ).then((res) => res.json());

    if (balanceResult.code === 0) {
      balance = BigInt(balanceResult.data.availableBalance);
      available = BigInt(balanceResult.data.availableBalanceSafe);
    }
  } catch (error) {}
  return {
    balance,
    composed_balance: {
      available,
    },
  };
}

// 1. 调用transfer_fee方法获取到三笔交易需要多少聪BTC手续费
// 	参数： session_key: 自定义一个唯一字符串， 这个字符串将和后续调用build_commit, build_reveal_transfer 参数中的session_key相同

// 2. 根据transfer_fee 选取不含有铭文的utxo， 选取的utxo输出的btc总量 > transfer_fee:

// 3. 调用build_commit_方法: 构建commit_tx: 参数说明:
// 	session_key: String, // session_key  与第1步中使用相同的值
//     vins: Vec<UtxoArgs>, //第2步中选中的utxo列表
//     token_id: TokenId,	 // tokenid:  如 Bitcoinbrc20-brc20-YCBS
//     amount: String,		// 转账数量： 用字符串表示： 可能是小数， 如 100、1000.22342，小数位数不超过decimal
//     sender: String,		// 客户自己的地址
//     target_chain: String, // 目标链chain_id 如  Bitfinity, eICP...
//     receiver: String,  //接收地址: 如 0x61359C8034534d4B586AC7E09Bb87Bb8Cb2F1561
//  	返回psbt base64编码的字符串
// 4. 对第3步返回的pbst签名， 获得签名后的signedPsbt,并计算tx hash (请前端研究一下)
// 5. 调用 build_reveal_transfer 接口，构建reveal, transfer交易， 参数说明：
//  	session_key: String, // session_key  与第1步中使用相同的值
//     commit_tx_id: String, //第4步计算出的tx_hash
//     返回结果： Vec<String>: 在成功的情况下，会返回长度为2 的字符串数组(vec), 第一元素是reveal交易的序列化后的16进制,第二个元素是transfer交易的psbt base64格式
// 6.对transfer交易的psbt 签名
// 7. 将 commit, reveal， transfer 3币交易发到bitcoin 网络
// 8. 待3笔交易都被接收之后发送generate_ticket, 参数：
// 	pub struct GenerateTicketArgs {
//     	pub txid: String, //请传递transfer 交易(第三笔)交易的tx_hash
//     	pub amount: String,	// 转账数量： 用字符串表示： 可能是小数， 如 100、1000.223，小数位数不超过decimal
//     	pub token_id: TokenId,	 // tokenid:  如 Bitcoinbrc20-brc20-YCBS
//     	pub target_chain_id: String, // 目标链chain_id 如  Bitfinity, eICP...
//     	pub receiver: String,  //接收地址: 如 0x61359C8034534d4B586AC7E09Bb87Bb8Cb2F1561
// }
