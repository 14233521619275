import { Text, HStack, VStack, Box, Circle } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { usePagination } from "src/hooks/usePagination";
import { useMemo } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { PAGE_SIZE } from "src/utils/constants";

const DOTS = "...";

export default function Pagination({
  page,
  total,
}: {
  page: number;
  total: number;
}) {
  const [_, setSearchParams] = useSearchParams();

  const paginationRange = usePagination({
    currentPage: page,
    totalCount: total,
    siblingCount: 1,
    pageSize: PAGE_SIZE,
  });

  const pageCount = useMemo(() => {
    return Math.ceil(total / PAGE_SIZE);
  }, [total]);

  if (total === 0) {
    return null;
  }

  return page === 0 ||
    (paginationRange && paginationRange.length < 2) ? null : (
    <HStack w="100%" alignItems="center" justifyContent="center" gap={4}>
      {page > 1 && (
        <ChevronLeft
          size={24}
          cursor="pointer"
          onClick={() => {
            if (page > 1) {
              setSearchParams({ page: String(page - 1) });
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }}
        />
      )}
      {(paginationRange ?? []).map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <Text fontSize={24} color="#FFF" mx="$2">
              &#8230;
            </Text>
          );
        }
        const active = pageNumber === page;
        return (
          <Circle key={pageNumber}>
            <Text
              cursor="pointer"
              color={active ? "blue.400" : "gray.400"}
              fontSize={24}
              minW="24px"
              _hover={{ color: "blue.400" }}
              className="number"
              onClick={() => {
                setSearchParams({ page: pageNumber });
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              {pageNumber}
            </Text>
          </Circle>
        );
      })}
      {page < pageCount && (
        <ChevronRight
          size={24}
          cursor="pointer"
          onClick={() => {
            if (page < pageCount) {
              setSearchParams({ page: String(page + 1) });
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }}
        />
      )}
    </HStack>
  );
}
