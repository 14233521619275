import {
  chakra,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  useDisclosure,
  VStack,
  useOutsideClick,
  Badge,
  Input,
} from "@chakra-ui/react";
import { ChainID } from "@omnity/widget/src/types";
import { useMetadata } from "src/contexts/metadata";
import { ChevronDown, XCircle } from "lucide-react";
import { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import IChainLogo from "./IChainLogo";
import { getChainName } from "src/utils/chains";

const ChevronDownIcon = chakra(ChevronDown);
const XCircleIcon = chakra(XCircle);

export default function TicketFilterChainId({
  chainId,
  setChainId,
  chainIds = [],
}: {
  chainId?: ChainID;
  setChainId: (chainId: ChainID | undefined) => void;
  chainIds?: ChainID[];
}) {
  const [keyword, setKeyword] = useState("");
  const ref = useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { chains } = useMetadata();
  useOutsideClick({
    ref,
    handler: onClose,
  });
  const [_, setSearchParams] = useSearchParams();
  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <HStack>
            <HStack
              borderWidth={0.5}
              borderColor="gray.500"
              _hover={{ bg: "gray.600" }}
              px={4}
              py={2}
              bg={chainId ? "gray.600" : "transparent"}
              cursor="pointer"
              onClick={onToggle}
            >
              {chainId ? (
                <HStack>
                  <IChainLogo chain={chainId} size={24} />
                  <Text>{getChainName(chainId)}</Text>
                </HStack>
              ) : (
                <Text>Chain</Text>
              )}
              <ChevronDownIcon size={24} />
            </HStack>
            <XCircleIcon
              size={20}
              color={chainId ? "blue.500" : "transparent"}
              cursor="pointer"
              onClick={() => {
                chainId && setChainId(undefined);
                setSearchParams({ page: "1" });
              }}
            />
          </HStack>
        </PopoverTrigger>
        <PopoverContent w={160} p={0} ref={ref}>
          <PopoverBody p={0}>
            <Input
              placeholder="Search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <VStack
              alignItems="flex-start"
              w="100%"
              gap={0}
              maxH={300}
              overflowY="scroll"
            >
              {chains
                .filter((chain) => {
                  if (chainIds.length === 0) {
                    return true;
                  }
                  return chainIds.includes(chain.chain_id as ChainID);
                })
                .filter((chain) => {
                  const chain_name = getChainName(chain.chain_id as ChainID);
                  if (keyword.trim()) {
                    return chain_name
                      .toLowerCase()
                      .includes(keyword.trim().toLowerCase());
                  }
                  return true;
                })
                .map((chain) => {
                  const _chainId = chain.chain_id as ChainID;
                  const chain_name = getChainName(_chainId);
                  const hasSameName =
                    chains.filter(
                      (c) => getChainName(c.chain_id as ChainID) === chain_name,
                    ).length > 1;

                  return (
                    <HStack
                      key={chain.canister_id}
                      cursor="pointer"
                      w="100%"
                      pl={4}
                      py={2}
                      bg={chainId === _chainId ? "gray.600" : "transparent"}
                      _hover={{ bg: "gray.600" }}
                      onClick={() => {
                        setChainId(chainId === _chainId ? undefined : _chainId);
                        setSearchParams({ page: "1" });
                        onClose();
                      }}
                    >
                      <IChainLogo chain={_chainId} size={24} />
                      <Text>{chain_name}</Text>
                      {hasSameName && (
                        <Badge fontSize={10} size="xs" colorScheme="blue">
                          {chain.chain_type === "ExecutionChain"
                            ? "Route"
                            : "Customs"}
                        </Badge>
                      )}
                    </HStack>
                  );
                })}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
