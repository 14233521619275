import ChainLogo from "@omnity/widget/src/components/ChainLogo";
import { ChainID, ChainName } from "@omnity/widget/src/types";
import { Osmosis } from "./icons";
import { CHAIN_ID_NAME_MAP } from "@omnity/widget/src/utils/constants";
import { getChainIdFromName } from "@omnity/widget/src/utils/chains";

export default function IChainLogo({
  size = 32,
  chain,
}: {
  size?: number;
  chain: ChainID | ChainName;
}) {
  if (CHAIN_ID_NAME_MAP[chain]) {
    return <ChainLogo size={size} chain={chain} />;
  } else {
    const chainIds = getChainIdFromName(chain as string);
    if (chainIds[0]) {
      return <ChainLogo size={size} chain={chainIds[0]} />;
    }
  }

  return <Osmosis size={size} />;
}
